import HomePage from "./Home";
import BackToTop from "./backToTop";

export default function Home() {
  return (
    <>
      <HomePage />
      {/* <BackToTop /> */}
    </>
  );
}
